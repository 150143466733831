
































































import { Component, Vue } from 'vue-property-decorator'
import AreaSelect from '@/components/area-select.vue'
import { apiSupplierAdd, apiSupplierCategoryLists, apiSupplierDetail, apiSupplierEdit } from '@/api/goods'
@Component({
  components: {
    AreaSelect
  }
})
export default class AddSupplier extends Vue {
  $refs!: { form: any }
  id!: any
  loading = false
  form = {
    code: '',
    name: '',
    supplier_category_id: '',
    contact: '',
    mobile: '',
    landline: '',
    email: '',
    city_id: '',
    district_id: '',
    province_id: '',
    address: '',
    bank_account: '',
    bank: '',
    cardholder_name: '',
    tax_id: '',
    sort: ''
  }

  rules = {
    code: [
      {
        required: true,
        message: '请输入供应商编号',
        trigger: ['blur', 'change']
      }
    ],
    name: [
      {
        required: true,
        message: '请输入供应商名称',
        trigger: ['blur', 'change']
      }
    ],
    supplier_category_id: [
      {
        required: true,
        message: '请选择供应商分类',
        trigger: ['blur', 'change']
      }
    ]
  }

  categoryLists = []
  handleSave() {
    this.$refs.form.validate((valid: boolean) => {
      if (valid) {
        const api = this.id ? apiSupplierEdit(this.form) : apiSupplierAdd(this.form)
        api.then(() => {
          this.$router.go(-1)
        })
      } else {
        return false
      }
    })
  }

  getSupplierCategory() {
    apiSupplierCategoryLists({}).then((res: any) => {
      this.categoryLists = res?.lists
    })
  }

  getSupplierDetail() {
    this.loading = true
    apiSupplierDetail(this.id).then((res: any) => {
      this.form = res
      this.loading = false
    })
  }

  created() {
    this.id = this.$route.query.id
    this.id && this.getSupplierDetail()
    this.getSupplierCategory()
  }
}
